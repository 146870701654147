import styled from 'styled-components';
import { Text } from '@strikelabs/luna';
import { rem } from 'polished';

import { Container } from 'components/common';

export const Outer = styled(Container)`
  padding-top: ${({ theme }) => theme.spacing.lg};
  padding-bottom: ${({ theme }) => theme.spacing.lg};
  margin-bottom: 0;

  ${({ theme }) => theme.media.desktop} {
    padding-top: ${({ theme }) => theme.spacing.xl};
    padding-bottom: ${({ theme }) => theme.spacing.xl};
  }
`;

export const IntroText = styled(Text).attrs({
  as: 'p',
  ext: true,
})`
  ${({ theme }) => theme.media.desktop} {
    /* max-width: 70%;
    font-size: ${rem(24)};
    line-height: ${rem(36)}; */
  }

  ${({ theme }) => theme.media.giant} {
    max-width: 60%;
  }
`;
