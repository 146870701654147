import React from 'react';
import LazyLoad from 'react-lazyload';

import Layout from 'components/Layout';
import SEO from 'components/SEO';
import { Outro, HowItWorksVideo } from 'components/Pages/HowItWorks/Sections';
import HowWeSellForFree from 'components/HowWeSellForFree';
import Stats from 'components/Stats';
import BrandImg from 'images/strike-brand.png';

const HowItWorks = () => (
  <Layout showFixedCta>
    <SEO
      title="Sell Your House For Free With Strike | How It Works"
      description="We're Here To Guide You Every Step Of The Way With A Better Way To Buy And Sell Your Home. Our Online Service Makes Moving Simple, Transparent and Free."
      canonical="/selling/how-it-works"
      metaImage={BrandImg}
    />
    <HowItWorksVideo />
    <LazyLoad height={661} offset={80}>
      <HowWeSellForFree />
    </LazyLoad>
    <LazyLoad height={392} offset={80}>
      <Stats />
    </LazyLoad>
    <Outro />
  </Layout>
);

export default HowItWorks;
