import React from 'react';
import {
  Box,
  Underline,
  Text,
  HideBreakpoint,
  ShowBreakpoint,
} from '@strikelabs/luna';

import useInView from 'hooks/useInView';
import Button from 'components/common/Button';
import Link from 'components/Link';

import { Outer, IntroText } from './style';

const Outro = () => {
  const { ref, isCenter } = useInView(true);

  return (
    <Box width={1} backgroundColor="backgroundLight">
      <Outer ref={ref}>
        <IntroText>
          It&apos;s time for the industry to change &mdash; and we&apos;re the
          ones changing it. No more jargon, no more high fees. Just a better way
          to sell your home.
        </IntroText>
        <Box mt={2}>
          <Text as="h2" ext>
            Get that{' '}
            <Underline active={isCenter}>sell-your-home-for-free</Underline>{' '}
            feeling
          </Text>
        </Box>
        <Box mt={4} textAlign={['center', 'center', 'left']}>
          <HideBreakpoint breakpoint="smallTablet">
            <Button styleType="ghost" fullWidth>
              Why Strike
            </Button>
          </HideBreakpoint>
          <ShowBreakpoint breakpoint="smallTablet">
            <Link to="/why-strike">
              <Button styleType="ghost">Why Strike</Button>
            </Link>
          </ShowBreakpoint>
        </Box>
      </Outer>
    </Box>
  );
};

export default Outro;
