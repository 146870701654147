import React from 'react';
import { Text } from '@strikelabs/luna';
import PropTypes from 'prop-types';

import {
  MortgageAdvice,
  MarketingBoosts,
  HostedViewings,
  MovingServices,
} from './Icons';
import {
  Outer,
  Inner,
  ColumnWrapper,
  ContentContainer,
  IconContainer,
  IconRow,
  IconBox,
} from './style';

const HowWeSellForFree = () => (
  <Outer>
    <Inner>
      <ColumnWrapper>
        <ContentContainer>
          <Text as="h3" styleType="h2" color="white" ext mb={[3, 3, 3, 4]}>
            How can we sell for free? Glad you asked.
          </Text>
          <Text as="p" color="white" ext mb={4}>
            We&apos;ve waved goodbye to pointlessly high fees and found a better
            way to sell your home. We make money through optional extras along
            the way, but you never have to use them. It&apos;s your sale,
            it&apos;s your choice.
          </Text>
        </ContentContainer>
        <IconContainer>
          <IconRow>
            <IconBox>
              <MortgageAdvice />
            </IconBox>
            <Text as="p" styleType="h3" ext color="white">
              Mortgage
              <br />
              advice
            </Text>
          </IconRow>
          <IconRow>
            <IconBox>
              <MarketingBoosts />
            </IconBox>
            <Text as="p" styleType="h3" ext color="white">
              Marketing
              <br />
              boosts
            </Text>
          </IconRow>
          <IconRow>
            <IconBox>
              <HostedViewings />
            </IconBox>
            <Text as="p" styleType="h3" ext color="white">
              Hosted
              <br />
              viewings
            </Text>
          </IconRow>
          <IconRow>
            <IconBox>
              <MovingServices />
            </IconBox>
            <Text as="p" styleType="h3" ext color="white">
              Moving
              <br />
              services
            </Text>
          </IconRow>
        </IconContainer>
      </ColumnWrapper>
    </Inner>
  </Outer>
);

HowWeSellForFree.propTypes = {
  noCta: PropTypes.bool,
};

HowWeSellForFree.defaultProps = {
  noCta: false,
};

export default HowWeSellForFree;
