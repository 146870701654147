import React, { useRef, useLayoutEffect, useState } from 'react';
import { Box, useIsMedia, Text, Button, Flex } from '@strikelabs/luna';
import { createEvent } from '@strikelabs/sputnik';

import Player from 'react-player';

import Link from 'components/Link';
import { HOW_IT_WORKS_VIDEO_URL } from 'constants/how-it-works-video';
import { ExtendedContainer as Container } from './style';

const HowItWorksVideo = () => {
  const { isTablet, isMobile } = useIsMedia();

  /**
   * The YouTube embed <iframe> requires an explicit width and height in order to render correctly.
   * In order to render the video at 16:9 using a width of 100%, we need to get the absolute value of 100%
   * in order to calculate the height correctly.
   */

  /**
   * Gets the width of the video container.
   */
  const widthRef = useRef();
  const [videoWidth, setVideoWidth] = useState(0);
  useLayoutEffect(() => {
    if (widthRef.current) {
      setVideoWidth(widthRef.current.offsetWidth);
    }
  }, []);

  /**
   * Given the video's width, return the height of a 16:9 aspect ratio.
   */
  const calcHeight = width => {
    const height = (width / 16) * 9;
    return height;
  };

  return (
    <Container>
      <Box ref={widthRef}>
        <Text as="h1" ext mt={[4, 4, 6]} mb={[4, 4, 5]}>
          How we&apos;ve made selling so easy
        </Text>
        <Flex justifyContent="center">
          <Player
            width="100%"
            height={calcHeight(videoWidth)}
            url={HOW_IT_WORKS_VIDEO_URL}
            onPlay={createEvent({
              category: 'How it works video',
              action: 'User clicked play',
            })}
          />
        </Flex>
        <Text mt={[4, 4, 5]} mb={4}>
          Ready for a better way to sell your home? Book your valuation in just
          a few clicks.
        </Text>
        <Link to="/book-valuation">
          <Button fullWidth={isMobile || isTablet}>
            Book a free valuation
          </Button>
        </Link>
      </Box>
    </Container>
  );
};

export default HowItWorksVideo;
