import React from 'react';

const HostedViewings = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 84 62"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.303 60.5277V32.7298H38.7521V20.6243H80.0006V60.5277H12.303Z"
      stroke="#2D7650"
      strokeWidth="2.33828"
    />
    <path
      d="M36.8133 32.6425L14.2773 31.949C12.4131 31.8917 11.1452 30.0351 11.77 28.2778L17.0291 13.4864C17.4191 12.3897 18.4571 11.6571 19.6211 11.6571H49.2699C49.9994 11.6571 50.6992 11.3673 51.215 10.8514L60.0602 2.00622C61.1581 0.908359 62.9464 0.936005 64.0098 2.06728L78.7821 17.7825C80.4328 19.5385 79.1878 22.4176 76.7777 22.4176H42.3998C40.8805 22.4176 39.6489 23.6492 39.6489 25.1685V29.8928C39.6489 31.4453 38.365 32.6902 36.8133 32.6425Z"
      fill="white"
    />
    <path
      d="M36.4496 32.7297H13.8231C11.9603 32.7297 10.6361 30.9171 11.2024 29.1424L16.1718 13.5716C16.5358 12.4312 17.5954 11.6571 18.7925 11.6571H48.3731C49.1027 11.6571 49.8024 11.3673 50.3183 10.8514L59.1635 2.00622C60.2614 0.908359 62.0497 0.936009 63.1131 2.06728L80.0639 20.1001C80.8892 20.9781 80.2667 22.4176 79.0617 22.4176H41.9514C40.4321 22.4176 39.2005 23.6492 39.2005 25.1685V29.9788C39.2005 31.4981 37.9689 32.7297 36.4496 32.7297Z"
      stroke="#2D7650"
      strokeWidth="2.33828"
    />
    <mask id="path-4-inside-1_300_1817" fill="white">
      <rect
        x="20.8179"
        y="39.455"
        width="8.07035"
        height="11.6572"
        rx="1.37546"
      />
    </mask>
    <rect
      x="20.8179"
      y="39.455"
      width="8.07035"
      height="11.6572"
      rx="1.37546"
      fill="white"
      stroke="#2D7650"
      strokeWidth="4.67656"
      mask="url(#path-4-inside-1_300_1817)"
    />
    <mask id="path-5-inside-2_300_1817" fill="white">
      <rect
        x="47.719"
        y="26.9009"
        width="8.07035"
        height="11.6572"
        rx="1.37546"
      />
    </mask>
    <rect
      x="47.719"
      y="26.9009"
      width="8.07035"
      height="11.6572"
      rx="1.37546"
      fill="white"
      stroke="#2D7650"
      strokeWidth="4.67656"
      mask="url(#path-5-inside-2_300_1817)"
    />
    <mask id="path-6-inside-3_300_1817" fill="white">
      <rect
        x="63.8599"
        y="26.9009"
        width="8.07035"
        height="11.6572"
        rx="1.37546"
      />
    </mask>
    <rect
      x="63.8599"
      y="26.9009"
      width="8.07035"
      height="11.6572"
      rx="1.37546"
      fill="white"
      stroke="#2D7650"
      strokeWidth="4.67656"
      mask="url(#path-6-inside-3_300_1817)"
    />
    <path
      d="M54.8926 60.0791V50.2153C54.8926 47.7392 56.8999 45.7318 59.3761 45.7318V45.7318C61.8523 45.7318 63.8596 47.7392 63.8596 50.2153V60.0791"
      stroke="#2D7650"
      strokeWidth="2.33828"
    />
    <path
      d="M39.2003 24.2111L39.2004 60.5277"
      stroke="#2D7650"
      strokeWidth="2.33828"
    />
    <path
      d="M1.98712 15.9802C6.0223 10.1072 19.0245 1.58856 31.1301 15.9802C26.1642 22.5628 14.9894 31.6282 1.98712 15.9802Z"
      fill="white"
      stroke="#2D7650"
      strokeWidth="2.33828"
    />
    <circle
      cx="16.3343"
      cy="16.3266"
      r="4.37413"
      stroke="#2D7650"
      strokeWidth="2.33828"
    />
  </svg>
);

export default HostedViewings;
