import styled from 'styled-components';
import { Flex } from '@strikelabs/luna';
import { rem } from 'polished';

import { Container } from 'components/common';

export const Outer = styled.div`
  background: ${({ theme }) => theme.colors.backgroundDark};
  padding-top: ${({ theme }) => theme.spacing.xl};
  padding-bottom: ${({ theme }) => theme.spacing.xl};

  ${({ theme }) => theme.media.desktop} {
    padding-top: ${({ theme }) => theme.spacing.xxl};
    padding-bottom: ${({ theme }) => theme.spacing.xxl};
  }
`;

export const Inner = styled(Container)`
  ${({ theme }) => theme.media.desktop} {
    margin-bottom: 0;
  }
`;

export const ColumnWrapper = styled(Flex)`
  flex-direction: column;
  ${({ theme }) => theme.media.desktop} {
    flex-direction: row;
    align-items: center;
  }
`;

export const ContentContainer = styled(Flex)`
  width: 100%;
  flex-direction: column;
  margin-bottom: ${({ theme }) => theme.spacing.xl};

  ${({ theme }) => theme.media.desktop} {
    width: 50%;
    padding-right: ${({ theme }) => theme.spacing.xl};
    margin-bottom: 0;
  }
`;

export const IconContainer = styled(Flex)`
  width: 100%;
  flex-direction: column;

  ${({ theme }) => theme.media.smallTablet} {
    width: 50%;
  }

  ${({ theme }) => theme.media.desktop} {
    flex-direction: row;
    padding-left: ${({ theme }) => theme.spacing.xl};
    justify-content: space-between;
  }
`;

export const IconRow = styled(Flex)`
  margin-bottom: ${({ theme }) => theme.spacing.lg};
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  ${({ theme }) => theme.media.desktop} {
    flex-direction: column;
    margin-bottom: 0;
    align-items: flex-start;
  }
`;

export const IconBox = styled(Flex)`
  width: ${rem(68)};
  height: ${rem(68)};
  background: ${({ theme }) => theme.colors.background};
  border-radius: ${rem(7)};
  padding: ${({ theme }) => theme.spacing.sm};
  order: 1;

  ${({ theme }) => theme.media.desktop} {
    width: ${rem(107)};
    height: ${rem(110)};
    padding: ${({ theme }) => theme.spacing.rg};
    margin-bottom: ${({ theme }) => theme.spacing.rg};
    order: 0;
  }
`;
