import React from 'react';

const MovingServices = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 71 72"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.5047 27.3056V47.5829M4.5047 27.3056L2.27006 24.4325C1.3721 23.278 1.53456 21.6227 2.63984 20.6648L23.5283 2.56144C24.5623 1.66533 26.0977 1.66533 27.1316 2.56144L48.0201 20.6648C49.1254 21.6227 49.2879 23.278 48.3899 24.4325L46.1553 27.3056M4.5047 27.3056L23.558 11.2607C24.582 10.3984 26.078 10.3984 27.1019 11.2607L46.1553 27.3056M11.0811 61.2837H7.25561C5.73632 61.2837 4.5047 60.0521 4.5047 58.5328V47.5829M26.4261 61.2837H46.1553M61.5002 61.2837H66.4218C67.9411 61.2837 69.1727 60.0521 69.1727 58.5328V48.4645C69.1727 47.8911 68.9936 47.3321 68.6603 46.8655L59.0349 33.3899C58.5185 32.667 57.6848 32.2379 56.7964 32.2379H46.1553M46.1553 27.3056V44.832C46.1553 46.3513 44.9237 47.5829 43.4044 47.5829H4.5047"
      stroke="#2D7650"
      strokeWidth="2.33828"
    />
    <path
      d="M53.2797 32.238V45.3801C53.2797 46.8994 54.5114 48.131 56.0306 48.131H69.7207"
      stroke="#2D7650"
      strokeWidth="2.33828"
    />
    <circle
      cx="18.7536"
      cy="62.928"
      r="7.05137"
      stroke="#2D7650"
      strokeWidth="2.33828"
    />
    <circle
      cx="53.8277"
      cy="62.928"
      r="7.05137"
      stroke="#2D7650"
      strokeWidth="2.33828"
    />
    <rect
      x="20.4708"
      y="26.2827"
      width="10.8145"
      height="10.8145"
      rx="1.58178"
      fill="white"
      stroke="#2D7650"
      strokeWidth="2.33828"
    />
  </svg>
);

export default MovingServices;
