import React from 'react';

const MarketingBoosts = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 54 77"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      width="41.0606"
      height="70.1317"
      rx="4.12637"
      transform="matrix(-1 0 0 1 42.3664 4.76764)"
      stroke="#2D7650"
      strokeWidth="2.33828"
    />
    <path
      d="M21.8363 65.7156C20.6771 65.7156 19.7413 66.6479 19.7413 67.8028C19.7413 68.9578 20.6771 69.8901 21.8363 69.8901C22.9955 69.8901 23.9312 68.9578 23.9312 67.8028C23.9312 66.6479 22.9955 65.7156 21.8363 65.7156"
      stroke="#2D7650"
      strokeWidth="2.33828"
    />
    <path
      d="M41.9044 61.2249L2.19513 61.1234"
      stroke="#2D7650"
      strokeWidth="2.33828"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M30.0204 35.6913V25.3998L21.521 18.1521L12.7898 25.3998V35.6913H30.0204Z"
      stroke="#2D7650"
      strokeWidth="2.33828"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M23.7636 34.5165V28.2826H18.8619V34.5165"
      stroke="#2D7650"
      strokeWidth="2.33828"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.6187 44.0061H30.2161"
      stroke="#2D7650"
      strokeWidth="2.33828"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.6187 49.4347H30.2161"
      stroke="#2D7650"
      strokeWidth="2.33828"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle
      cx="42.4036"
      cy="12.3783"
      r="10.3782"
      fill="white"
      stroke="#2D7650"
      strokeWidth="2.33828"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M42.9601 7.30932L44.4957 10.1662L47.9479 10.6259C48.0639 10.6405 48.161 10.7141 48.1988 10.816C48.2366 10.9179 48.2086 11.0308 48.1264 11.1075L45.6385 13.2966L46.2218 16.4381C46.2426 16.5437 46.1971 16.6511 46.104 16.7158C46.011 16.7806 45.8862 16.7917 45.7814 16.7446L42.7101 15.2559L39.6151 16.7774C39.5102 16.8245 39.3855 16.8134 39.2924 16.7486C39.1993 16.6839 39.1538 16.5765 39.1746 16.4709L39.7579 13.3295L37.2343 11.1075C37.1521 11.0308 37.1241 10.9179 37.1619 10.816C37.1997 10.7141 37.2968 10.6405 37.4128 10.6259L40.8412 10.1662L42.3768 7.30932C42.4207 7.19582 42.5375 7.12 42.6685 7.12C42.7994 7.12 42.9163 7.19582 42.9601 7.30932Z"
      fill="#2D7650"
    />
  </svg>
);

export default MarketingBoosts;
